import React from "react"
import Layout from "../components/Layout"
import logo from "../../static/logo.svg"
import SEO from "../components/seo"
const NotFound = () => {
  return (
    <Layout>
      <SEO
        title={`Editorial`}
        lang="ca"
        keywords={[
          "noticies",
          "blog",
          "blog de les males herbes",
          "llibres",
          "literatura catalana",
          "terror",
          "ciència ficció",
          "neogrotesc",
          "clàssics",
          "surrealisme",
          "editors independents",
          "males herbes",
          "editorial",
          "narrativa",
          "català",
          "catalana",
          "autor",
          "autora",
          "escriptora",
        ]}
      />
      <div className="flex flex-wrap">
        <div className="w-full">
          <p className="font-title font-bold uppercase text-gray-900 text-xl border-b border-black">
            L'editorial
          </p>
        </div>
      </div>

      <div class="flex flex-wrap mb-4 -mx-2 -my-4 mt-12">
        <div class="w-full md:w-1/2 px-4 py-4">
          {/* <Image fluid={autor.frontmatter.imatge.sharp.fluid} /> */}
          <div className="w-full bg-white h-64 md:h-128 ">
            <img src={logo} className="h-48 w-full" />
          </div>
        </div>
        <div class="w-full md:w-1/2 px-4 py-3 text-lg mb-32">
          <p>
            L’editorial Males Herbes va néixer l’any 2012 com a continuació
            natural de la revista Les Males Herbes. La nostra intenció era
            ocupar un espai molt desatès en el panorama literari català. El que
            oferíem era una col·lecció de narrativa en format butxaca, amb un
            disseny cuidat i un preu assequible, que apostés per obres que
            s’escapéssin del cànon realista predominant en la literatura
            catalana, movent-nos a gust per territoris com la sàtira, el
            realisme màgic, la prosa experimental, el neogrotesc, la
            ciència-ficció, el terror, el surrealisme o qualsevol altre tipus de
            prosa que assumeixi el risc de crear el seu propi món i el seu propi
            llenguatge. Tot plegat amb la modesta voluntat d’ajudar a enfortir
            una contracultura feta en català.
          </p>
          <br />
          <p>
            Aquesta primera col·lecció, anomenada Distorsions, té tres vessants:
            l’edició d’obres de culte estrangeres; el descobriment i promoció de
            nous talents en català; i la reivindicació d’algunes obres d’autors
            d’aquí que, per no encaixar amb els cànons predominants, no van
            tenir el ressò que es mereixien en el seu moment.
          </p>
          <br />
          <p>
            {" "}
            Al llarg d’aquests primers anys el projecte s’ha anat consolidant.
            També hem iniciat una col·lecció de teatre, una de no-ficció i una
            altra de còmic, i hem publicat alguns llibres fora de col·lecció,
            sense perdre de vista els tres pilars que aguanten el nostre
            catàleg, que són la sàtira, la contracultura i la temàtica
            fantàstica. Així hem volgut traçar, de manera bastant clara, les
            línies generals del cànon literari que reivindiquem.
          </p>
          <br />
          <p>Ricard Planas i Ramon Mas</p>
          <br />
          <p>Editorial Males Herbes</p>
        </div>
      </div>
    </Layout>
  )
}

export default NotFound
